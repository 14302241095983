<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Users</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'Home'}">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Users</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <!-- Main content -->
    <section class="content" v-if="has_permission === true">
      <div class="container-fluid">
      <div class="card">
        <div class="card-header">
          <a href="javascript:void(0)" class="btn btn-info" @click="showFilter = !showFilter"><i
            class="fa fa-filter"></i>Filter</a>
          <div class="card-tools">
            <router-link :to="{name: 'UserAdd'}" class="btn btn-success">
              <i class="fas fa-plus"></i> New
            </router-link>
            <button class="btn btn-info" @click="exportFile()">
              <i class="fas fa-download"></i> Export
            </button>
          </div>
        </div>
        <form @submit.prevent="getUsers" method="GET" novalidate>
          <div class="card-body" v-if="showFilter">
            <div class="form-row">
              <div class="col-md-6">
                <div class="form-group form-row">
                  <label class="col-md-3 col-form-label" for="name">Name</label>
                  <div class="col-md-9">
                    <input type="text" class="form-control" id="name" v-model="filter.name" placeholder="Name">
                  </div>
                </div>
                <div class="form-group form-row">
                  <label class="col-md-3 col-form-label" for="mobile_no">Mobile No</label>
                  <div class="col-md-9">
                    <input type="text" class="form-control" id="mobile_no" v-model="filter.mobile_no"
                           placeholder="Mobile No">
                  </div>
                </div>
                <div class="form-group form-row">
                  <label class="col-md-3 col-form-label" for="email">Email</label>
                  <div class="col-md-9">
                    <input type="text" class="form-control" id="email" v-model="filter.email" placeholder="Email">
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group form-row">
                  <label class="col-md-3 col-form-label" for="organization">Organization</label>
                  <div class="col-md-9">
                    <multiselect
                      id="organization"
                      v-model="filter.organization_id"
                      :options="pre.organizations.map(org => org.id)"
                      :custom-label="opt => pre.organizations.find(x => x.id == opt).org_name"
                      placeholder="Pick Organization" :searchable="true" :multiple="false"
                      class="email"
                    >
                    </multiselect>
                  </div>
                </div>
                <!-- Date range -->
                <div class="form-group form-row">
                  <label class="col-md-3 col-form-label" for="created_at">Date range</label>
                  <div class="col-md-9">
                    <date-picker type="datetime" input-class="form-control" v-model="filter.created_at" range
                                 lang="en" format="YYYY-MM-DD hh:mm:ss" confirm id="created_at"
                                 value-type="YYYY-MM-DD hh:mm:ss"></date-picker>
                  </div>
                </div>
              </div>

            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-9 offset-md-3">
                <button type="submit" class="btn btn-common m-2px"><i class="fas fa-search"></i> Search</button>
                <button type="reset" class="btn btn-warning" @click="resetFilter()"><i class="fas fa-undo"></i> Reset</button>
              </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div class="overlay" v-if="is_loading">
          <i class="fas fa-2x fa-sync-alt fa-spin"></i>
        </div>
        <div class="card-body p-0">
         <div class="table-responsive">
            <table class="table">
            <thead>
            <tr>
              <th style="width: 10px">ID</th>
              <th>Username</th>
              <th>Name <sort-icons :_sort="'name'"/></th>
              <th>Mobile No <sort-icons :_sort="'mobile_no'"/></th>
              <th>Email <sort-icons :_sort="'email'"/></th>
              <th>Parent</th>
              <th>Organization</th>
              <th>Roles</th>
              <th>Department</th>
              <th class="text-right">Action</th>
            </tr>
            </thead>
            <tbody class="users-list">
              <tr v-for="(val, index) in list.data" :key="index">
                <td>{{ val.id }}</td>
                <td>{{ val.username }}</td>
                <td>{{ val.name }}</td>
                <td>{{ val.mobile_no }}</td>
                <td>{{ val.email }}</td>
                <td>{{ !_.isEmpty(val.parent) ? val.parent.name : 'NA' }}</td>
                <td>{{ !_.isEmpty(val.organization) ? val.organization.org_name : '' }}</td>
                <td><span class="badge bg-success" v-for="(roleValue, roleIndex) in val.roles" :key="roleIndex">{{ roleValue.name }}</span>
                </td>
                <td>{{ !_.isEmpty(val.department) ? val.department.name : '' }}</td>
                <td class="text-right">
                  <router-link :to="{ name: 'UserView', params: { id: val.id }}" class="btn btn-info">
                    <i class="fas fa-eye"></i>
                  </router-link>
                  <router-link :to="{ name: 'UserEdit', params: { id: val.id }}" class="btn btn-info">
                    <i class="fas fa-pencil-alt"></i>
                  </router-link>
                  <a class="btn btn-danger" href="javascript:void(0)" @click="deleteUser(val.id, index)"><i
                    class="fas fa-trash"></i></a>
                </td>
              </tr>
            </tbody>
          </table>
         </div>
          <div class="p-4" v-if="_.isEmpty(list.data)">
            <div class="alert alert-default-warning" role="alert">
              No data found!
            </div>
          </div>
        </div>
        <div class="card-footer">
          <vue-pagination
            :pagination="list"
            v-if="list.total > list.per_page"
            @paginate="getUsers()"
          />
        </div>
      </div>
      </div>
    </section>

    <section class="content" v-if="has_permission === false">
      <div class="alert alert-danger alert-dismissible">
        <h5><i class="icon fas fa-ban"></i> Permission denied!</h5>
      </div>
    </section>
  </div>
</template>

<script>

import VuePagination from '@/components/partials/PaginationComponent'
import SortIcons from '@/components/utils/SortIcons'
import axios from 'axios'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { CommonAPI } from '@/services/api'
import { Notification } from 'element-ui'
// eslint-disable-next-line @typescript-eslint/no-var-requires
const fileDownload = require('js-file-download')

export default {
  name: 'UserList',
  metaInfo: {
    title: 'User List | Dotlines'
  },
  components: {
    DatePicker,
    VuePagination,
    SortIcons
  },
  data: () => ({
    list: {
      total: 0,
      per_page: 1,
      from: 1,
      to: 0
    },
    order_by: 'asc',
    api_url: '',
    showFilter: false,
    filter: {},
    dateRange: '',
    pre: [],
    is_loading: false,
    has_permission: true
  }),
  mounted: function () {
    if (this.isMobile()) {
      window.$(document).find('.push-menu-class').trigger('click');
    }
    this.api_url = '/admin_users'
    this.getUsers()
    this.getOrganization()
    this.$root.$on('sort-data', () => {
      this.$root.$emit('show-icon')
      this.getUsers()
    })
  },
  methods: {
    exportFile: function () {
      this.is_loading = true
      axios.get('/admin-users-export', { responseType: 'blob' })
        .then(response => {
          // console.log(response.data);
          fileDownload(response.data, 'admin-users.xlsx')
        })
        .catch((error) => {
          console.log('error ' + error)
        })
        .finally(() => {
          this.is_loading = false
          Notification.success({ title: 'Export Completed', message: 'Export operation completed successfully', type: 'success' })
        })
    },
    customFormatter (date) {
      // eslint-disable-next-line no-undef
      return moment(date).format('YYYY-MM-DD hh:mm:ss')
    },
    getUsers () {
      this.is_loading = true
      const query = { ...this.filter, ...this.$route.query }
      this.apiUsers(query).then(list => {
        this.list = list
      }).finally(() => {
        this.is_loading = false
      })
    },
    apiUsers(query = null) {
      return new Promise((resolve, reject) => {
        axios.get(this.api_url, {
          params: query
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          if (error.response.status === 403) {
            this.has_permission = false
          }
          reject(error)
        })
      })
    },
    async getOrganization () {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const _this = this
      await CommonAPI.getAllOrganizations().then(organizations => {
        _this.pre.organizations = organizations
      })
    },
    deleteUser: function (id, index) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const _this = this
      // eslint-disable-next-line camelcase
      const delete_api_url = this.api_url + '/' + id

      _this.$swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        confirmButtonText: 'Yes, delete it!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33'
      }).then((result) => {
        if (result.isConfirmed) {
          _this.is_loading = true
          axios.delete(delete_api_url)
            .then(response => {
              _this.list.data.splice(index, 1)
              _this.$toaster.success(response.data.message)
              _this.is_loading = false
            })
            .catch(error => {
              _this.is_loading = false
              console.log(error)
              _this.$swal.fire('Failed!', 'Failed to delete.', 'error')
            })
        }
      })
    },
    resetFilter () {
      const query = Object.assign({}, this.$route.query)
      if (Object.keys(query).length > 0) {
        delete query.order_by
        delete query.order_key
        delete query.page
        // Object.keys(query).forEach((key) => delete query.key);
        this.$router.replace({ query })
      }
      this.filter = {}
      this.getUsers()
    }
  }
}

</script>
